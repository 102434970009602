import { REFRESH_EVENT } from '../../utils/reload';
import EVENTS from '../../constants/events';
import { BI_EVENT } from '../../bi/constants';
import { WidgetProps } from '../../../../Widget.types';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/dist/env/hosts';
import { experimentsStore } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { ReadOnlyExperiments } from '@wix/wix-experiments';
import { ControllerParams, IWixAPI } from '@wix/yoshi-flow-editor';
import { getViewMode } from './getViewMode';

export { getV3ToV2MappingBaseUrl } from './getV3ToV2MappingBaseUrl';
export { getViewMode };

export function getInitialPubSubEvents(): { [key: string]: any } {
  return {
    [REFRESH_EVENT]: [],
    [EVENTS.SESSION.LOGOUT_USER]: null,
    [BI_EVENT]: null,
  };
}

export function patchControllerConfig(
  controllerConfig: ControllerParams['controllerConfig'],
  { getEvents }: { getEvents(): any },
) {
  const pubSubEvents = getInitialPubSubEvents();
  const newSetProps = (props: WidgetProps) => {
    controllerConfig.setProps({
      events: getEvents(),
      pubSubEvents,
      ...props,
    });
  };
  return { ...controllerConfig, setProps: newSetProps };
}

export function getSiteLocale(wixCodeApi: IWixAPI) {
  const { window, site } = wixCodeApi;
  const { multilingual } = window;

  if (multilingual.isEnabled) {
    return multilingual.currentLanguage;
  }

  return site.language;
}

export async function getExperimentsFromPetri(
  config: ControllerParams['controllerConfig'],
  isViewer: boolean,
) {
  let experiments: ReadOnlyExperiments;

  if (isViewer) {
    const createExperiments = config?.essentials?.createExperiments;
    const createExperimentsOptions = { scopes: ['vod'] };
    experiments = createExperiments?.(createExperimentsOptions) ?? null;
  } else {
    experiments = config?.essentials?.experiments;
  }

  if (experiments) {
    await experiments.ready();
    const bag = experiments.all();
    experimentsStore.set(bag);
    return bag;
  }

  return {};
}

export function getApiBaseUrl(wixCodeApi: IWixAPI) {
  const isSite = getViewMode(wixCodeApi) === VIEW_MODES.SITE;
  return isSite ? wixCodeApi.location.baseUrl : PRODUCTION_HOSTS.manage;
}

/**
 * Fixes mesh recalculation dor dynamic content in ThunderBolt.
 * Related to https://jira.wixpress.com/browse/PLAT-1743
 * @returns {{fitToContentHeight: boolean}}
 * @private
 */
export function getMeshOverrides() {
  return {
    fitToContentHeight: true,
  };
}
